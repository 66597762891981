.logos-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 50px;
    /* margin-left: 15px; */
  }

  .logo-title{
    font-family: inter;
  font-size: 1.95rem;
  margin-bottom: 50px;
  }
  
  .logo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
  }
  
  .logo {
    max-width: 100%;
    height: auto;
  }

  .logo4 {
        max-width: 50%;
        height: auto;
      }

      .logo5 {
        max-width: 70%;
        height: auto;
      }