.map-section {
  text-align: center;
  background-color: none;
  padding: 2rem 0;
  margin-bottom: 50px;
}

.map-image {
  padding: 2rem 0;
  width: 100%;
  max-width: 600px;
  height: auto;
  margin-bottom: 20px;
}

.map-title {
  font-family: playfair display, serif;
  font-size: 1.75rem;
  margin-bottom: 50px;
}

.map-info {
  display: flex;
  margin: 1rem 0;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-family: playfair display, serif;
  font-weight: 500;
  font-size: 1.2rem;
}

.contactbutton {
  margin-top: 10px;
  background-color: black;
  color: white;
  padding: 1rem;
  border: none;
  width: 200px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  cursor: pointer;
  font-family: inter;
  border-radius: 5rem;
}

@media (max-width: 480px) {
  .map-image {
    width: 80%; /* Reduce padding for mobile devices */
  }

  .map-info {
    font-size: 1.1rem; /* Adjust font size for mobile devices */
    flex-wrap: wrap; /* Wrap the content if necessary */
    gap: 10px; /* Reduce the gap between items */
  }
}
