.benefits-section {
    background-color: none;
    padding: 2rem 0;
    /* border-bottom: 1px solid black; */
  }

  .benefits-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .benefits-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }

  .audioclips{
    margin: 20px auto;
  }

  .text-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
  }

  .benefits-bottom {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    align-items: flex-start;
    margin: 100px;
 }

 .benefits-sales{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  margin: 100px;
 }

  /* .benefits-bottom img, 
.benefits-bottom .benefits-list {
  flex: 1;
} */

.another{
  display: flex;
  justify-content: center;
}

.subtext{
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  font-weight: 500;
  color: #7c7c7c;
  width: 80%;
  align-items: center;
}

  .how-we-help{
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  width: 60%;
  font-weight: 500;
  color: #7c7c7c;
  }

 .benefit-title{
  font-family: inter;
  font-size: 1.95rem;
  justify-content: center;
 }

 .mock-image, .mock-image4 {
   width: 90%; /* Adjust the image width */
   max-width: 1000px; /* Set a max-width for larger screens */
   height: auto; /* Maintain the aspect ratio */
   display: block; /* Display the image as a block element */
   margin: 0 auto; /* Center the image horizontally */
   object-fit: cover; /* Resize the image while maintaining its aspect ratio */
 }

 .mock-image3, .mock-image2{
  width: 30%; /* Adjust the image width */
   max-width: 450px; /* Set a max-width for larger screens */
   height: auto; /* Maintain the aspect ratio */
   display: block; /* Display the image as a block element */
   margin: 10px; /* Center the image horizontally */
   object-fit: cover; 
 }


  .benefits-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
    padding: 1rem;
  }
  
  .benefit-item {
    width: 30%;
    /* background-color: #fff; */
    padding: 3rem;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    border-radius: 5px;
  }
  
  .benefit-item h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .benefit-item p {
    font-size: 1.25rem;
  }

  .learn-more-button{
    margin-top: 10px;
    background-color: black;
    color: white;
    padding: 1rem;
    border: none;
    width: 200px; /* Add this line to set the button width */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    cursor: pointer;
    font-family: inter;
    border-radius: 0.25rem;
    margin-bottom: 50px;
  }

  .get-touched-btn{
    margin-top: 10px;
    background-color: black;
    color: white;
    padding: 1rem;
    border: none;
    width: 200px; /* Add this line to set the button width */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    cursor: pointer;
    font-family: inter;
    border-radius: 5rem;
    margin-bottom: 100px;
  }

  @media screen and (max-width: 991px) {
    .benefits-list {
      flex-direction: column;
    }
  
    .benefit-item {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
  
  @media screen and (max-width: 767px) {
    .benefits-section {
      padding: 1rem 0;
    }

    .benefits-top, .benefits-bottom, .benefits-sales {
      flex-direction: column;
      margin: auto;
    }
 
    .text-container{
      width: auto;
      margin: auto;
    }

    .how-we-help{
      font-size: 1rem;
      line-height: 1.5;
      margin-bottom: 2.5rem;
      width: 60%;
      font-weight: 500;
      color: #7c7c7c;
      }

    .benefit-title{
      margin: 50px;
      margin-top: 20px;
    }

    .mock-image2 {
      width: 250px;
      margin-bottom: 3rem;
      margin: auto;
    }
  
    .benefit-title {
      text-align: center;
      font-size: 1.5rem;
      margin: 20px 0;
    }
  
    .subtext {
      text-align: center;
      font-size: 1rem;
      margin-bottom: 2.5rem;
      width: 200px;
    }
  
  
    .benefit-item {
      width: 100%;
      padding: 0rem;
    }
  
    .benefit-item h3 {
      font-size: 1rem;
    }
  
    .benefit-item p {
      font-size: 1rem;
    }
  }
  
  @media screen and (max-width: 575px) {
    .benefits-list {
      padding: 0;
    }
  }