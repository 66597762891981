.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  text-align: center;
  background-color: none;
  padding: auto;
}



.hero-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 70px;
  padding-right: 70px;
  max-width: 80%;
  box-sizing: border-box;
}

.hero-title {
  font-size: 4.5rem;
  width: 80%;
  margin: 0;
  font-family: playfair display, serif;
  font-weight: 600;
  /* white-space: nowrap; */
  text-decoration: none;
  margin-top: 75px;
}
/* 
.navbar-link {
  color: inherit;
  text-decoration: none;
}

.navbar-link:hover {
  color: inherit;
  text-decoration: none;
} */

.hero-subtitle {
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  width: 60%;
  font-weight: 500;
  color: #7c7c7c;
}

.get-started-btn {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: black;
  color: white;
  padding: 1rem;
  border: none;
  width: 180px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  cursor: pointer;
  font-family: inter;
  border-radius: 5rem;
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 3rem;
    width: auto;
    margin-bottom: 10px;
  }

  .benefits-list {
    margin-top: 100px; /* Adjust as needed */
  }

    .benefit-item {
      max-width: 70%; /* Adjust as needed */
      margin: 0 auto; /* Centers the content */
    }


  .get-started-btn {
    margin-bottom: 20px;
  }

  .hero-subtitle {
    font-size: 1rem;
    width: auto;
    margin-bottom: 20px;
  }

  .hero-content {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 2.5rem;
    white-space: normal;
  }

  .hero-subtitle {
    font-size: 0.875rem;
  }
}