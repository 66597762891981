.footer {
    background-color: #fff;
    padding: 2rem;
    text-align: center;
    color: #333;
    display: inline-block;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin: 2rem;
    border-radius: 8px;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    font-size: smaller;
  }
  
  .footer-links {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 0;
    margin-top: 1rem;
  }
  
  .footer-links a {
    text-decoration: none;
    color: #333;
    transition: color 0.3s;
  }
  
  .footer-links a:hover {
    color: #666;
  }