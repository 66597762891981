.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: none;
  border-bottom: 1px solid #e8e8e8;
  position: relative;
  text-decoration: none;
}

.navbar-link {
  color: inherit;
  text-decoration: none;
}

.navbar-link:hover {
  color: inherit;
  text-decoration: none;
}


.mobile-title {
  display: none;
}


.navbar-title {
  color: black;
  font-size: 1.25rem;
  font-family: Playfair Display, serif;
  font-weight: 600;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
}

.custom-btn {
  background-color: transparent;
  color: #7C7C7C;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.95rem;
  cursor: pointer;
  font-family: inter;
  font-weight: 500;
  border-radius: 0.25rem;
  margin-right: 20px;
}


.get-started-btnn {
  background-color: black;
  color: white;
  padding: 0.7rem 1.55rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.95rem;
  cursor: pointer;
  font-family: inter;
  font-weight: 500;
  border-radius: 1.25rem;
  margin-right: 10px;
}

.get-started-btnn {
  margin-right: 0;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 18px;
  cursor: pointer;
}

.menu-toggle span {
  display: block;
  height: 3px;
  background-color: black;
}

@media (max-width: 768px) {
  .buttons-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
  }

  .navbar-title{
    display: none;
  }

  .mobile-title {
    display:block;
    color: black;
    font-size: 1.25rem;
    font-family: Playfair Display, serif;
    font-weight: 600;
    text-decoration: none;
  }


  .mobile-title-link,
  .mobile-title-link:visited,
  .mobile-title-link:hover,
  .mobile-title-link:active {
    text-decoration: none !important;
    color: inherit !important;
  }

  .buttons-container.open {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  .menu-toggle {
    display: flex;
  }

  .custom-btn,
  .get-started-btnn {
    margin-bottom: 1rem;
  }
}